import {
  GelContainerLite,
  GelFormStep,
  GelFormStepper,
  GelMultiStepForm,
  GelBoxLayout,
  GelButton,
} from "@tal-gel/components";
import styled from "@emotion/styled";
import { GhqMemberDetailsStep } from "./GhqMemberDetailsStep";
import { ContactInformationStep } from "./GhqContactInformationStep";
import { AuthorisationStep } from "./GhqAuthorisationStep";
import { GhqReviewClaim } from "./GhqReviewClaim";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Fund } from "../../models/Fund";
import { GhqBenefitDetailsStep } from "./GhqBenefitDetailsStep";
import { exitToGHQ } from "./GhqLogout";

interface FormProps {
  lodgement: any;
  contactDetails: any;
  setLodgement: (lodgement: any) => any;
  setShowForm: (showForm: boolean) => void;
  setShowReview: (showForm: boolean) => void;
  saveLodgement: (lodgement: any) => any;
  funds: Fund[] | null;
  selectedFund: string;
  setSelectedFund: (fundId: string) => void;
  setScreen: (screen: string) => void;
  setLoading: (loading: boolean) => void;
  setLodgementType : (lodgementType: string) => void;
}

const GhqForm = (props: FormProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const navigate = useNavigate();
  const [IsReview, setIsReview] = useState(false);
  const backToDashBoard = () => {
    navigate("/");
  };

  const setIsUploadingWrapper = (isUploading: boolean) => {
    setIsUploading(isUploading);
  };

  const IsReviewScreen = (screen: string) => {
    if (screen === "Review") {
      setIsReview(true);
    }
  };

  const {
    contactDetails,
    lodgement,
    setLodgement,
    setShowForm,
    selectedFund,
    setShowReview,
    saveLodgement,
    funds,
    setSelectedFund,
    setLoading,
    setScreen,
    setLodgementType,
  } = props;
  return (
    <GelContainerLite fixed gutter={{ vertical: "large" }}>
      <GelMultiStepForm
        render={() => (
          <>
            <GelFormStepper>
              <GelFormStep title="Member details" data-testid="member-details">
                <GhqMemberDetailsStep
                  lodgement={lodgement}
                  saveLodgement={saveLodgement}
                  funds={funds}
                  setSelectedFund={setSelectedFund}
                  setScreen={setScreen}
                />
              </GelFormStep>
              <GelFormStep
                title="Benefit details"
                data-testid="benefit-details"
              >
                <GhqBenefitDetailsStep
                  lodgement={lodgement}
                  saveLodgement={saveLodgement}
                  funds={funds}
                  setScreen={setScreen}
                  setLoading={setLoading}
                  setLodgementType={setLodgementType}
                />
              </GelFormStep>
              <GelFormStep
                title="Member's contact information"
                data-testid="contact-information"
              >
                <ContactInformationStep
                  contactDetails={contactDetails}
                  lodgement={lodgement}
                  saveLodgement={saveLodgement}
                  setScreen={setScreen}
                />
              </GelFormStep>
              <GelFormStep
                title="Third-party authorisation"
                data-testid="authorisation"
              >
                <AuthorisationStep
                  lodgement={lodgement}
                  setLodgement={setLodgement}
                  saveLodgement={saveLodgement}
                  setIsUploadingForm={setIsUploadingWrapper}
                  funds={funds}
                  setScreen={setScreen}
                />
              </GelFormStep>
              <GelFormStep>
                <GhqReviewClaim
                  setShowForm={setShowForm}
                  setShowReview={setShowReview}
                  setIsReviewScreen={IsReviewScreen}
                />
              </GelFormStep>
            </GelFormStepper>
          </>
        )}
      />
      <GelBoxLayout space="auto" distribution="start">
        {!IsReview && ( // Conditionally show button if not on Review screen
          <GelButton
            secondary
            medium
            onClick={exitToGHQ}
            disabled={isUploading}
          >
            EXIT TO HOME PAGE
          </GelButton>
        )}
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export const StyledFormContainer = styled.div`
  ${() =>
    `
    text-align: left;
    width: 50%
  `}
`;

export const StyledFormContainerMain = styled.div`
  ${() =>
    `
    text-align: left;
    width: 100%
  `}
`;

export default GhqForm;
